@use "../../styles/" as *;
// @import "../../styles/index";

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 32rem 64rem;
    overflow-x: hidden;
    @include tablet() {
        padding: 12rem 12rem;
    }
    &._project {
        background-color: white;
        padding: 0;
        .header {
            padding: 32rem 64rem;
            background-color: $black;
            margin: 0;
            @include tablet() {
                padding: 12rem 12rem;
            }
        }
        .footer {
            padding: 0 12rem;
            padding: 32rem 64rem;
            background-color: $black;
            @include tablet() {
                padding: 12rem 12rem;
            }
        }
    }
}
