@use "./variables" as *;
// @import "./variables";




@mixin fnt($size: 16, $height: 24, $color: $white, $weight: 400, $font: $font, $unit: 'rem') {
  font-family: $font;
  font-style: normal;
  @if ($font == $font) {
    font-weight: unset;
    font-variation-settings: "wght" $weight;
  } @else {
    font-weight: $weight;
  }
  font-size: #{$size}#{$unit};
  line-height: #{$height}#{$unit};
  color: $color;
}

@mixin btn($bg-color: $gray-600, $color: #fff) {
  @include fnt(16, 24, $color !important, 600);
  display: flex;
  padding: 12rem 24rem;
  background-color: $bg-color;
  border-radius: 8rem;
  width: fit-content;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
  cursor: pointer;
  border: none;
  &:hover {
    text-decoration: none;
    color: $color !important;
    background-color: darken($bg-color, 5%);
  }
  &._block {
    width: unset;
    justify-content: center;
  }
  &._disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: 8rem;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: var(--gray-200);
  }
  &::-webkit-scrollbar-button {
    height: 4rem;
  }
  //scrollbar-gutter: stable;
  scrollbar-gutter: auto;
}

@mixin size($width: 24, $height: false) {
  width: #{$width}rem;
  @if ($height) {
    height: #{$height}rem;
  } @else {
    height: #{$width}rem;
  }
}
