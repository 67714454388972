@use "/src/styles/" as *;

.Content {
    margin-top: 32rem;
    margin-bottom: 40rem;
    &__img {
        width: 100%;
        max-height: 800rem;
        object-fit: contain;
    }
    &__text {
        @include fnt(16, 24, $black, 400);
        margin: 16rem 0;
        b{
            color: $black;
            font-variation-settings: "wght" 700;
        }
        a{
            &:hover{
                cursor: pointer;
            }
        }
    }
    &__document {
        padding: 4rem 12rem 4rem 4rem;
        img {
            height: 24rem;
            width: 24rem;
            margin-right: 8rem;
        }
    }
    &__link {
        position: relative;
        padding: 4rem 20rem 4rem 12rem;
        img {
            position: absolute;
            top: 4rem;
            right: 4rem;
            height: 12rem;
            width: 12rem;
        }
    }
    &__link,
    &__document {
        display: flex;
        align-items: center;
        background-color: $gray-500;
        border-radius: 8rem;
        width: fit-content;
        cursor: pointer;
        @include fnt();
        margin: 16rem 0;
        border: 2px solid $gray-500;
        transition: background-color $tr;
        &:hover {
            // background-color: transparent;
        }
    }
}
