
@font-face {
  font-family: "PTRoot";
  src:
    url("../assets/fonts/PT_Root/pt-root-ui_vf.woff2") format("woff2"),
    url("../assets/fonts/PT_Root//pt-root-ui_vf.woff") format("woff");
}
@font-face {
  font-family: "PTMono";
  src: url("../assets/fonts/PT_Mono/PTMono-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
