@use "/src/styles/" as *;

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: grid;
  // place-items: center;
  // background-color: rgba(0, 0, 0, .4);
  pointer-events: none;
  // opacity: 0;
  transition: opacity 0.3s;
  overflow: auto;
  z-index: 1000;
  color: $black;
  overflow-x: hidden;
  overflow-y: auto;
  &__content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 48rem 48rem 32rem 48rem;
    margin: 0 0 0 auto;
    min-width: 360rem;
    max-width: 100vw;
    min-height: 100%;
    height: fit-content;
    width: 960rem;
    transform: translateX(100%);
    transition: transform 0.3s;
    &._small {
      width: 420rem;
    }
    &._lightbox {
      padding: 0;
      min-width: unset;
      overflow: hidden;
      border-radius: 4rem;
      img {
        max-width: 100%;
      }
    }
    @include tablet() {
      padding: 12rem 12rem 8rem 12rem;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  &__title {
    @include fnt(48, 52, $black, 700);
    margin: 0;
    @include tablet() {
      @include fnt(32, 48, $black, 700);
    }
  }
  &__description {
    @include fnt(20, 32, $gray-500, 500);
    margin: 0;
    @include tablet() {
      @include fnt(18, 24, $gray-500, 500);
    }
  }
  &__x-close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10rem;
    border-radius: 12rem;
    background-color: $white;
    cursor: pointer;
    transition: background-color $tr;
    flex: none;
    border: none;
    &::after {
      content: url("../../../assets/interface_icons/x-close.svg");
      display: block;
      height: 32rem;
      width: 32rem;
    }
    &:hover {
      background-color: $gray-100;
    }
  }
  &.active {
    // opacity: 1;
    pointer-events: all;
  }
  &.active &__content {
    transform: translateX(0);
  }

  &__buttons-panel {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8rem;
    align-items: center;
    margin: 24rem 0;
    * {
      justify-self: end;
    }
  }
}
.modal.inventory .modal__content {
  min-width: unset;
}
