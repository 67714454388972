@use "../../styles/" as *;

// @import "../../styles/index";



.footer {
    display: flex;
    flex-direction: column;
    &-top {
        display: flex;
        justify-content: space-between;
        @include tablet {
            flex-direction: column-reverse;
            gap: 8rem;
        }
    }
    &-bottom {
        display: flex;
        justify-content: space-between;
    }
    .licenses {
        display: flex;
        align-items: center;
        .year {
            @include fnt(14, 20, $gray-400, 600);
            margin-right: 8rem;
        }
        &-icons {
            display: flex;
            gap: 2rem;
            margin-right: 4rem;
            img {
                @include size(20);
                flex: none;
                &:hover{
                    cursor: default;
                }
            }
        }
        &-text {
            .title {
                @include fnt(10, 10, $gray-400, 600);
            }
            .subtitle {
                @include fnt(10, 10, $gray-400, 400);
            }
            
        }
    }
    &-terms{
        @include fnt(14, 20, $gray-400, 400, $font);
    }
    &-contacts{
        @include fnt(16, 24, $gray-400, 500);
    }
    .logo-mark {
        display: block;
        @include size(24);
        margin-right: 8rem;
        use {
            fill: $gray-400;
        }
    }
    h1{
        @include fnt(16, 24, $gray-400, 700);
    }
}
