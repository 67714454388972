@use "../../styles/" as *;
// @import "../../styles/index";

.contacts {
    &-block {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24rem;
    }
    &-phone {
        @include fnt(64, 72, $white, 400);
        @include tablet() {
            @include fnt(24, 32, $white, 400);
        }
    }
    &-email {
        @include fnt(64, 72, $white, 700);
        margin-bottom: 12rem;
        @include tablet() {
            @include fnt(24, 32, $white, 700);
            margin-bottom: 0;
        }
    }
    &-socials {
        display: flex;
        margin-top: 40rem;
        gap: 12rem;
        flex-wrap: wrap;
        .social-link {
            display: block;
            @include fnt(20, 24, $white, 400);
            border: 1px solid $white;
            border-radius: 12rem;
            padding: 23rem 63rem;
            transition: all 0.3s;
            
            &:hover {
                &._0 {
                    background-color: #2787f5;
                    border-color: transparent;
                }
                &._1 {
                    background-color: #36afe3;
                    border-color: transparent;
                }
            }
            @include tablet () {
                flex-grow: 1;
                text-align: center;
                padding: 12rem 48rem;
                @include fnt(16, 24, $white, 400);
            }
        }
        @include tablet () {
            margin-top: 24rem;
        }
    }
}
.discuss-project {
    // background-color: $gray-500;
    background: url("../../assets/interface_img/card-hole.svg") $gray-500 no-repeat;
    background-position: center 24rem;
    background-size: auto 36rem;
    border-radius: 24rem;
    padding: 24rem 32rem;
    aspect-ratio: 591 / 296;
    width: 100%;
    max-width: 591rem;
    display: flex;
    align-items: flex-end;
    &:hover {
        cursor: pointer;
    }
    &__btn {
        @include fnt(32, 40, $white, 500);
        display: flex;
        justify-content: space-between;
        width: 100%;
        &:after {
            content: "";
            display: block;
            background: url("../../assets/interface_img/arrow-right.svg") no-repeat center;
            @include size(40);
            background-size: 40rem;
        }
    }
}
.requisites {
    display: flex;
    flex-direction: column;
    gap: 12rem;
    margin-top: 24rem;
    &-title {
        @include fnt(32, 40, $white, 600);
    }
    &-list {
        .label {
            @include fnt(20, 32, $gray-400, 400);
        }
        .data {
            @include fnt(24, 32, $white, 400);
        }
    }
    .copy-btn {
        @include fnt(16, 24, $gray-400, 400);
        display: flex;
        margin-left: 16rem;
        &:before {
            content: "";
            display: block;
            background: url("../../assets/interface_icons/copy.svg") no-repeat center;
            @include size(24);
            background-size: 24rem;
            flex: none;
            margin-right: 8rem;
        }
    }
}
.partners {
    margin-top: 32rem;
    &-title {
        @include fnt(16, 24, $white, 400);
        margin-left: 32rem;
    }
    &-item {
        padding: 16rem 24rem;
        background-color: $gray-900;
        width: fit-content;
        display: flex;
        align-items: center;
        @include fnt(14, 18, $white, 400);
        border-radius: 12rem;
        max-width: 320rem;
        min-width: 240rem;
        &:hover {
            cursor: pointer;
        }
    }
    &-list {
        display: flex;
        gap: 12rem;
        margin: 12rem 0 8rem;
        flex-wrap: wrap;
    }
}
.contacts-page_line {
    border-color: white;
    margin: 64rem 0 16rem;
}
