@use "/src/styles/" as *;

.container-884 {
    width: 100%;
    max-width: 884rem;
    margin: 0 auto;
}
.container-1186 {
    width: 100%;
    max-width: 1186rem;
    margin: 0 auto;
}

.Project {
    &__title {
        display: block;
        @include fnt(56, 64, $black, 700);
        @include mobile {
            @include fnt(28, 36, $black, 700);
        }
    }
    &__date {
        display: block;
        @include fnt($color: $gray-400);
        margin-top: 16rem;
    }
    h1 {
        @include fnt(56, 64, $white, 700);
        @include tablet {
            @include fnt(28, 36, $white, 700);
        }
    }
    h2 {
        @include fnt(48, 48, $black, 700);
        margin-bottom: 16rem;
    }
    h3 {
        @include fnt(16, 24, $black, 700);
    }
    ._project-info {
        background-color: $black;
        padding: 32rem 64rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet() {
            padding: 12rem 12rem;
        }
        .Project__title,
        .Project__date {
            max-width: 884rem;
            width: 100%;
        }
    }
    ._project-content{
        padding: 0 12rem;
    }
}
