@use "../../styles/" as *;

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8rem;
    grid-auto-rows: 1fr;
    grid-auto-flow: row dense;
    @include tablet {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: unset;
    }
}

.colItem-1 {
    grid-column: span 1; /* Растягивается на 1 колонку */
    aspect-ratio: 1/1;
    @include tablet {
        grid-column: span 1;
        aspect-ratio: 1/2;
    }
}

.colItem-2 {
    grid-column: span 2; /* Растягивается на 2 колонки */
    // aspect-ratio: 2/1;
    @include tablet {
        grid-column: span 2;
        aspect-ratio: 1/1;
    }
}

.colItem-3 {
    grid-column: span 3; /* Растягивается на 3 колонки */
    // aspect-ratio: 3/1;
    @include tablet {
        grid-column: span 2;
        aspect-ratio: 2/3;
    }
}

// .colItem-1,
// .colItem-2,
// .colItem-3 {
//     @include mobile {
//        aspect-ratio: 1/1;
//     }
// }

.fixed-item {
    position: relative;
    overflow: hidden;
    .project-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
        pointer-events: none;
    }
}

.project-item {
    border-radius: 24rem;
    position: relative;
    padding: 32rem;
    // height: 592rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: border-radius 0.3s;
    @include tablet {
        padding: 16rem;
    }
    &.light-background {
        .project-item_tag {
            background: $gray-800;
            color: $white;
        }
        .project-item_content .project-title {
            color: $gray-800;
        }
        .project-item_content .project-description {
            color: $gray-800;
        }
        .project-item_content .project-description._own {
            border: 1px solid $gray-800;
        }
        .project-item_views {
            color: $gray-800;
            .views-img {
                use {
                    stroke: $gray-800;
                }
            }
        }
    }
    &:hover {
        border-radius: 0rem;
        .project-item_content .project-title {
            opacity: 66%;
        }
        .project-item_content .project-description {
            opacity: 100%;
        }
    }
    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 5;
        pointer-events: none;
        .project-item_tags {
            display: flex;
            gap: 4rem;
        }
        .project-item_tag {
            padding: 0 12rem;
            background-color: $white;
            border-radius: 12rem;
            @include fnt(16, 24, $gray-800, 500);
        }
    }
    &_content {
        display: flex;
        flex-direction: column;
        gap: 8rem;
        z-index: 5;
        pointer-events: none;
        .project-title {
            @include fnt(24, 32, $white, 500);
            transition: opacity 0.3s;
        }
        .project-description {
            @include fnt(20, 24, $white, 500);
            opacity: 66%;
            transition: opacity 0.3s;
            &._own {
                @include fnt(14, 16, $white, 500);
                padding: 2rem 12rem;
                border: 1px solid white;
                border-radius: 10rem;
                width: fit-content;
            }
        }
    }
    &_views {
        @include fnt(14, 20, $white, 500);
        display: flex;
        gap: 4rem;
        opacity: 66%;
        .views-img {
            @include size(20);
            use {
                stroke: $white;
            }
        }
    }
    .project-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
    }
}
