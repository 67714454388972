@use "/src/styles/" as *;

.form__btn,
.select-item__control--is-disabled,
textarea,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="url"] {
  border-style: solid;
  appearance: none;
  &:focus {
    outline: none;
  }
  &:read-only {
    background-color: $gray-20 !important;
  }
}

select:focus {
  outline: none;
}
.date-range-wrap {
  display: flex;
  align-items: center;
  .form__item {
    margin: 0;
  }
  .date-range__hyphen {
    @include fnt(14, 24, $text-gray, $unit: "px");
    margin: 0 12px;
  }
}
.form__item {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  height: 100%;
  gap: 4px;
  background-color: $gray-200;
  border-radius: 12px;
  overflow: hidden;
  ._read-only {
    .select-item {
      &__control {
        background-color: $gray-20;
        &:hover {
          cursor: default;
        }
      }
      &__input {
        opacity: 0 !important;
      }
    }
  }

  div {
    label {
      // @include fnt(14, 20, $text-color, 400, $unit: 'px');
    }
  }
  label {
    @include fnt(14, 20, $text-color, 500, $unit: "px");
    margin-left: 12px;
    margin-bottom: 0;
    a {
      margin: 0 8px;
    }
    span {
      color: $red !important;
      //font-size: 16px;
      //line-height: 18px;
      // margin-left: 4px;
    }
  }
  .select,
  textarea,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="url"],
  select {
    display: block;
    padding: 24px;
    height: auto;
    @include fnt(20, 24, $black, $unit: "px");
    border: none;
    background-color: transparent;

    &:-webkit-autofill {
      background-color: transparent;
    }
    & + label {
      position: absolute;
      top: 24px;
      left: 24px;
      transition:
        top $tr,
        font-size $tr;
      pointer-events: none;
      margin-left: 0;
    }
    &.filled,
    &:focus,
    &.error {
      + label {
        // styles label for active input
        top: 4px;
        font-size: 12px;
      }
    }
    &.error {
      border-color: $red !important;
      + label {
        color: $red !important;
      }
    }
    &:disabled {
      background-color: $light;
    }
  }
  textarea {
    height: fit-content;
  }
  .select,
  select {
    padding: 10px 31px 10px 12px;
    appearance: none;
    background-image: url("../../../assets/interface_icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right 16px top 50%;
  }
  .select.show {
    border-radius: 8px 8px 0 0;
  }
  //               React-select start

  .select-item {
    &__control {
      border: 1px solid rgba(0, 16, 61, 0.12);
      border-radius: 8px;
      padding: 10px 12px;
      @include fnt(14, 20, $unit: "px");
      background-color: #fff;
      cursor: pointer;
      width: 100%;
      box-shadow: none;
      &:hover {
        border: 1px solid rgba(0, 16, 61, 0.12);
      }
    }
    &__input-container {
      @include fnt(14, 20, $unit: "px");
    }
    &__indicator {
      padding: 0;
      svg {
        display: none;
      }
    }
    &__dropdown-indicator {
      background: url("../../../assets/interface_icons/select-arrow.svg") no-repeat center;
      display: block;
      height: 24px;
      width: 24px;
      background-size: 16px;
    }
    &__value-container {
      padding: 0;
    }
    &__single-value {
      margin: 0;
    }
    &__input-container {
      margin: 0;
      padding: 0;
    }
    &__indicator-separator {
      display: none;
      width: 0;
      margin: 0;
    }
    &__multi-value {
      background-color: #fff;
      border: 1px solid $cyan;
      border-radius: 12px;
      &__label {
        @include fnt(14, 20, $text-color, 400, $unit: "px");
        padding: 0 0 0 12px;
      }
      &__pxove {
        &:hover {
          background-color: transparent;
          color: $red;
        }
      }
    }
  }
  //                   React-select end
  .form__checkbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &._horizontal {
      flex-direction: row;
    }
    input {
      display: none;
    }
    label {
      //display: flex;
      position: relative;
      @include fnt(14, 20, $unit: "px");
      margin-bottom: 16px;
      margin-left: 20px;
      margin-right: 24px;
      a {
        margin: 0;
        @include fnt(14, 20, $red, $unit: "px");
        text-decoration: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -20px;
        top: 0;
        flex: none;
        height: 16px;
        width: 16px;
        background-image: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
        box-shadow: inset 0 0 0 2px $gray-700;
        border-radius: 4px;
        margin: 2px 0 0 0;
      }
    }
    input:checked + label {
      &:before {
        background-image: url("../../../assets/interface_icons/checkmark.svg");
        box-shadow: inset 0 0 0 2px $gray-600;
        background-color: $gray-600;
      }
    }
    input:checked:disabled + label {
      &:before {
        box-shadow: inset 0 0 0 2px $gray-700;
        background-color: $gray-700;
      }
    }
    &._horizontal {
      label {
        margin-bottom: 0;
      }
    }
  }
  .select-item__input {
    background-color: transparent;
  }
  .form__radio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &._horizontal {
      flex-direction: row;
    }
    input {
      display: none;
    }
    label {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      margin-left: 0;
      margin-right: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        flex: none;
        height: 16px;
        width: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
        box-shadow: inset 0 0 0 2px $gray-700;
        border-radius: 50%;
        margin: 0 4px 0 0;
      }
    }
    input:checked + label {
      &:before {
        box-shadow: inset 0 0 0 2px $gray-600;
        //background: rgb(9,9,121);
        background: radial-gradient(circle, $gray-600 3.6px, #ffffff 4.2px);
      }
    }
    input:disabled:checked + label {
      &:before {
        box-shadow: inset 0 0 0 2px $gray-700;
        //background: rgb(9,9,121);
        background: radial-gradient(circle, $gray-700 3.6px, #ffffff 4.2px);
      }
    }
    &._horizontal {
      label {
        margin-bottom: 0;
      }
    }
  }
}
.error-message {
  @include fnt(14, 20, $red, $unit: "px");
  display: block;
  min-height: 16px;
  margin: 4px 0 0;
}
.help-text {
  @include fnt(14, 20, $text-color-secondary, $unit: "px");
  display: block;
  min-height: 16px;
  margin: 0 12px;
}
.form__btn {
  display: none;
}
.form__error {
  @include fnt(14, 20, $red, $unit: "px");
  display: block;
  margin: 0 0 12px;
}
.form__label {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  @include fnt(14, 20, $text-color, 500, $unit: "px");
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: font-variation-settings 0.3s ease-out;
  &:before {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M5 14L11 8L5 2' stroke='%232C2D2E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 16px;
    width: 16px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    font-variation-settings: "wght" 500;
    &:before {
      transform: translateX(8px);
    }
  }
}

.file__item.document-template {
  display: flex;
  align-items: center;
}
.file__item {
  background-color: transparent;
  border-radius: 0;
  &_content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &_footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &_wrap {
    display: flex;
    align-items: center;
    width: 100%;
    &:before {
      content: "";
      display: block;
      flex: none;
      height: 24px;
      width: 24px;
      background: url("../../../assets/interface_icons/file.svg") no-repeat center;
      background-size: contain;
      margin: 0 8px 0 0;
    }
  }
  .file__label {
    display: flex;
    @include fnt(20, 32, $black, 600, $unit: "px");
    margin: 0;
  }
  .file__label-img {
    display: block;
    height: 24px;
    width: 24px;
    margin: 4px 4px 0 0;
  }
  .help-text {
    margin: 0;
  }
  input {
    display: none;
  }
  .bold-title {
    @include fnt(16, 24, $black, 600, $unit: "px");
    margin-left: 0;
  }
  .add-file__btn {
    display: flex;
    gap: 4px;
    width: fit-content;
    flex: none;
    padding: 16px 24px 16px 16px;
    border: 1px solid $gray-400;
    border-radius: 12px;
    @include fnt(20, 24, $gray-800, 500, $unit: "px");
    text-align: center;
    margin: 0 !important;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    img {
      height: 24px;
      width: 24px;
    }
    &:hover {
      background-color: $gray-50;
    }
    &._disabled {
      background-color: $gray-50;
      color: $black;
      pointer-events: none;
    }
    &._block {
      width: 100%;
    }
  }
  .form__item {
    margin-bottom: 0;
  }
  .file-label {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 32px - 24px);
    // &:hover {
    //   color: $black;
    //   text-decoration: underline;
    // }
  }
  .delete-file {
    display: block;
    height: 24px;
    width: 24px;
    flex: none;
    margin-left: 4px;
    background: url("../../../assets/interface_icons/delete.svg") no-repeat center;
    cursor: pointer;
  }
}

// color

input[type="color"] {
  display: block;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  background: #fff;
  overflow: hidden;
  outline: none;
  cursor: inherit;
}

/* -webkit */
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

/* firefox */
input[type="color"]::-moz-focus-inner {
  border: none;
  padding: 0;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  height: 32px;
}
.color-field {
  &__container {
    display: flex;
    gap: 4px;
  }
  &__choice {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border-color: transparent;
    border-width: 0;
    border-style: solid;
    border-width: 1px;
    transition: border-color $tr;
    &:hover {
      border-color: #bfc1c7;
    }
    cursor: pointer;
    .choice__color {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    .choice__plus {
      position: absolute;
      height: 20px;
      width: 20px;
      pointer-events: none;
    }
    .choice__checkmark {
      display: none;
      position: absolute;
      height: 20px;
      width: 20px;
      pointer-events: none;
    }
    &.active {
      // .choice__color {
      //   background-image: url("../../..../../../assets/interface_icons/autodesign/check.svg");
      //   background-position: center;
      //   background-size: 20px;
      //   background-repeat: no-repeat;
      // }
      .choice__checkmark {
        display: block;
      }
    }
  }
}
.form__item .switch,
.switch {
  &__input {
    display: none;
  }
  &__toggler {
    display: inline-block;
    background-color: $gray-700;
    padding: 2px;
    height: 16px;
    width: 26px;
    border-radius: 10px;
    transition: background-color $tr;

    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      margin-left: 0;
      border-radius: 8px;
      transition: margin-left $tr;
      background-color: $white;
    }
    &.active {
      background-color: $gray-500;
      &::after {
        margin-left: 10px;
      }
    }
  }
  &__label {
    margin: 0 0 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    @include fnt(14, 16, $text-color-secondary, $unit: "px");
    transition: color $tr;

    &.active {
      color: $text-color;
    }
  }
  .error-message,
  .help-text {
    margin: 0;
  }
}
