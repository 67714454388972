@use "/src/styles/" as *;

.contacts-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12rem;
        margin-top: 64rem;
        @include tablet() {
            margin-top: 32rem;
        }
        .form__item {
            grid-column: span 3;
        }
    }
    &__control {
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        margin-top: 44rem;
        gap: 12rem;
        @include tablet() {
            margin-top: 24rem;
            flex-direction: column
        }
    }
    &__btn {
        @include btn();
        min-width: 280rem;
        display: flex;
        justify-content: center;
        @include tablet () {
            padding: 8rem 12rem;
        }
    }
    &__success-text {
        @include fnt($color: $gray-500);
        margin-top: 32rem;
        a {
            @include fnt($color: $gray-500, $weight: 700);
            &:hover {
                color: $gray-400;
            }
        }
    }
}
