@use "../../styles/" as *;
.running-line {
    display: flex;
    gap: 32rem;
    position: relative;
    &_block {
        background-color: $gray-400;
        margin: 8rem -64rem 68rem;    
    }
    &_text{
        @include fnt(20, 32, $gray-700, 600);
    }
    &_title{
        padding: 0 4rem;
        background-color: $gray-200;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.items-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 32rem;
  }
  .items {
    flex-shrink: 0;
    display: flex;
    gap: 32rem;
    counter-reset: item;
    justify-content: space-around;
    // min-width: 100%;
  }

  .marquee {
    animation: scroll 20s linear infinite;
  }

  
@keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 32rem));
    }
  }
  h2{
    @include fnt(48, 64, $gray-950, 700);
  }
