@use "../../styles/" as *;


.terms{
    &_title{
        @include fnt(56, 64, $white, 700);
        margin-bottom: 64rem;
    }
    &_text{
        @include fnt(16, 24, $white, 400);
        strong{
            @include fnt(16, 24, $white, 700);
        }
        p{
            margin-bottom: 12rem;
        }
    }
}