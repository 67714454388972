@use "./variables" as *;
// @import "./variables";


// $mobile-width: 600;
// $tablet-width: 1024;
// $laptop-width: 1440;
// $hd-width: 1920;





@mixin mobile {
    @media only screen and (max-width: #{$mobile-width}px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: #{$tablet-width}px) {
        @content;
    }
}

@mixin laptop {
    @media only screen and (max-width: #{$laptop-width}px) {
        @content;
    }
}
@mixin hd {
    @media only screen and (max-width: #{$hd-width}px) {
        @content;
    }
}
@mixin hd-plus {
    @media only screen and (min-width: #{$hd-width}px) {
        @content;
    }
}