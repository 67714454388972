@import "_fonts";

$font: "PTRoot", sans-serif;
$font-mono: "PTMono", monospace;


$black: #05080f;
$white: #ffffff;
$light: #f3f3f5;
$gray-20: #f9f9fa;
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;
$gray-950: rgb(12, 17, 29);
$text-gray: #919399;
$text-color: #2c2d2e;
$text-color-secondary: $text-gray;
$border: $gray-500;
$red: #e53948;
$green: #0dc268;
$cyan: #00cccc;
$bg-color: $black;

$mobile-width: 600;
$tablet-width: 1024;
$laptop-width: 1440;
$hd-width: 1920;

$tr: 0.2s ease-out;
