@use "/src/styles/" as *;

.GallerySlider {
    display: block;
    width: 100%;
    margin: 32rem 0;
    // aspect-ratio: 2 / 1;
    &__slider {
        margin-bottom: 10rem;
    }
    &__thumbs {
        padding: 2rem;
        margin: 0 -2rem;
    }
    &__img {
        width: 100%;
        aspect-ratio: 1186 / 668;
        object-fit: cover;
        object-position: center;
        border-radius: 4rem;
    }
    &__thumb-img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
        border-radius: 4rem;
        cursor: pointer;
        &._active {
            outline: 1px solid $border;
        }
    }
    &__next,
    &__prev {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 72rem;
        position: absolute;
        top: 0;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        opacity: .4;
        transition: opacity $tr;
        &:hover {
            opacity: 1;
        }
        img {
            height: 80rem;
            width: 40rem;
        }
        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0;
        }
    }
    &__prev {
        left: 0;
        transform: rotate(180deg);
    }
    &__next {
        right: 0;
    }
    .swiper  {
        overflow: visible;
    }
}