@use '/src/styles/' as *;

.GalleryTile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin: 32rem 0;
    &__img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
        border-radius: 4rem;
    }
}

.GalleryTile_flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    .GalleryTile__img {
        width: auto;
        height: 224rem;
        object-fit: cover;
        aspect-ratio: unset;
        flex-grow: 1;
        object-position: center;
        border-radius: 4rem;
    }
}