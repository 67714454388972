@use '/src/styles/' as *;

.Loader {
    display: flex;
    // height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 80rem 0;
    &__text {
        @include fnt(16, 24, $white, 400, $font-mono);
    }
}