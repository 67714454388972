@use "../../styles/" as *;
.scene {
    height: 100%;
    width: 100%;
    perspective: 1000px;
    position: relative;
}
.digital-gerb-animate {
    width: 100%;
    height: 100%;
    padding: 32rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0044CC;
}
.main_screen-grid {
    transform: translate(-50%, -50%);
    max-width: 665px;
}
.main_screen-grid,
.main_screen-img {
    position: absolute;
    transform-style: preserve-3d;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
#scene_grid {
    transform: translate(-50%, -50%) rotateY(var(--mouse-x, 0deg)) rotateX(var(--mouse-y, 0deg)) translateZ(-40px)
        scale(1.04);
}
#scene_gerb {
    transform: translate(-50%, -50%) rotateY(var(--mouse-x, 0deg)) rotateX(var(--mouse-y, 0deg));
}
#scene_frame {
    transform: translate(-50%, -50%) rotateY(var(--mouse-x, 0deg)) rotateX(var(--mouse-y, 0deg)) translateZ(40px)
        scale(0.96);
}
