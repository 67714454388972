@use "/src/styles/" as *;

@import "./reboot";
// @import "./variables";

html {
    font-size: 1.2px;
    @include hd-plus {
        font-size: clamp(1px, calc(100 * 100vw / $hd-width / 100), 1.2px);
      }
      @include laptop {
        font-size: clamp(0px, calc(100 * 100vw / $laptop-width / 100), 1.2px);
      }
      @include tablet {
        font-size: clamp(0px, calc(100 * 100vw / 400 / 100), 7px);
      }
}
// @media screen and (max-width: $laptop-width) {
//     html {
//         font-size: calc((100 * 100vw / 1024 / 100));
//     }
// }
// @media screen and (max-width: $tablet-width) {
//     html {
//         font-size: calc((100 * 100vw / 700 / 100));
//     }
// }
// @media screen and (max-width: $mobile-width) {
//     html {
//         font-size: calc((100 * 100vw / 375 / 100));
//     }
// }
body {
    display: block;
    width: 100%;
    font-size: 16rem;
    line-height: 24rem;
    color: $white;
    margin: 0;
    background-color: $bg-color;
}
#root {
    display: block;
    height: 100%;
}
a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
    &:hover {
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
}
img {
    user-select: none;
}
.cursor-pointer {
    cursor: pointer;
}

.bg {
    background-color: $bg-color;
}
hr {
    border: 0;
    border-bottom: 1px solid $border;
    width: 100%;
}
.w-100 {
    width: 100%;
}

b {
    font-weight: unset;
}
.content {
    flex-grow: 1;
    margin-bottom: 96rem;
}
$indents: 0, 4, 6, 8, 12, 16, 24, 32, 40, 48, 54, 96;
.m {
    @each $margin in $indents {
        &-#{$margin} {
            margin: #{$margin}rem !important;
        }
        &t-#{$margin} {
            margin-top: #{$margin}rem !important;
        }
        &b-#{$margin} {
            margin-bottom: #{$margin}rem !important;
        }
        &l-#{$margin} {
            margin-left: #{$margin}rem !important;
        }
        &r-#{$margin} {
            margin-right: #{$margin}rem !important;
        }
        &y-#{$margin} {
            margin-top: #{$margin}rem !important;
            margin-bottom: #{$margin}rem !important;
        }
        &x-#{$margin} {
            margin-right: #{$margin}rem !important;
            margin-left: #{$margin}rem !important;
        }
    }
}
.p {
    @each $padding in $indents {
        &-#{$padding} {
            padding: #{$padding}rem !important;
        }
        &t-#{$padding} {
            padding-top: #{$padding}rem !important;
        }
        &b-#{$padding} {
            padding-bottom: #{$padding}rem !important;
        }
        &l-#{$padding} {
            padding-left: #{$padding}rem !important;
        }
        &r-#{$padding} {
            padding-right: #{$padding}rem !important;
        }
        &y-#{$padding} {
            padding-top: #{$padding}rem !important;
            padding-bottom: #{$padding}rem !important;
        }
        &x-#{$padding} {
            padding-right: #{$padding}rem !important;
            padding-left: #{$padding}rem !important;
        }
    }
}

$cols: 1, 2, 3, 4, 5, 6;
@each $col in $cols {
    .col-#{$col} {
        grid-column: span $col;
    }
}
$rows: 1, 2, 3, 4, 5, 6;
@each $row in $rows {
    .row-#{$row} {
        grid-row: span $row;
    }
}

.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.justify-space-between {
    justify-content: space-between;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}

a.gr-button:hover {
    text-decoration: none;
    color: white;
}

$grid-lines: 1, 2, 3, 4, 5, 6;
@each $line in $grid-lines {
    ._grid-start-#{$line} {
        grid-column-start: $line;
    }
    ._grid-end-#{$line} {
        grid-column-end: $line;
    }
    ._grid-row-#{$line} {
        grid-row-start: $line;
    }
    ._grid-row-#{$line} {
        grid-row-end: $line;
    }
}

._red {
    color: $red !important;
}
._green {
    color: $green !important;
}
