@use "../../styles/" as *;

.header {
    margin-bottom: 52rem;
    .language__button {
        @include fnt(14, 16, $gray-400, 400, $font-mono);
        display: flex;
        border: 1px solid $gray-400;
        background: transparent;
        border-radius: 16rem;
        padding: 4rem 12rem 4rem 8rem;
        transition: all 0.3s;
        .lang-icon{
            display: block;
            flex: none;
            @include size(16);
            margin-right: 4rem;
            use {
                stroke: $gray-400;
                transition: stroke 0.3s;
            }
        }
       
        &:hover {
            background-color: white;
            border: 1px solid transparent;
            color: $gray-800;
            cursor: pointer;
            .lang-icon{
                use {
                    stroke: $gray-800;
                }
            }
        }
        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
    }
    .logo-mark {
        display: block;
        @include size(24);
        margin-right: 8rem;
        use {
            fill: white;
        }
    }
    h1{
        @include fnt(16, 24, $white, 700);
    }
}
